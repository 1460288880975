<template>
  <b-container fluid>
    <ValidationObserver ref="customerNoteObserver" v-slot="{ invalid }">
      <form>
        <b-row>
          <b-col>
            <b-form-group label-cols-md="3" content-cols-md="8" label-align-md="right"
                          :label="$t('NoteType')" label-for="noteType">
              <v-select transition="" v-model="model.customerNoteTypeID"
                        :reduce="s => s.id" label="name"
                        :options="noteTypeList">
              <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
            </b-form-group>
            <b-form-group label-cols-md="3" content-cols-md="8" label-align-md="right"
                          :label="$t('Notes')" label-for="notes">
              <ValidationProvider :name="$t('Notes')" rules="required" v-slot="{ errors }">
                <b-form-textarea rows="2" :class="(errors.length > 0 ? ' is-invalid' : '')" v-model="model.notes" name="Notes" type="text"></b-form-textarea>
                <b-form-invalid-feedback>{{ $t('ValidationMessage.CannotBeEmpty') }}</b-form-invalid-feedback>
              </ValidationProvider>
            </b-form-group>
            <b-form-group label-cols-md="3" content-cols-md="8" label-align-md="right"
                          :label="$t('RemindDate')" label-for="remindDate">
              <b-row>
                <b-col md="8">
                  <date-picker v-model="model.remindDate"
                               :tag-name="'remindDate'"
                               :is-required="false" />
                </b-col>
                <b-col md="4">
                  <time-picker v-model="model.time"
                               :is-required="false"
                               :start-time="'00:00'"
                               :step-time="'00:15'"
                               :end-time="'24:00'"></time-picker>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label-cols-md="3" content-cols-md="8" label-align-md="right"
                          :label="$t('Staff')" label-for="staff">
              <v-select transition="" v-model="model.staffID"
                        :reduce="s => s.id" label="name"
                        :options="staffList">
              <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
            </b-form-group>
            <b-form-group label-cols-md="3" content-cols-md="8" label-align-md="right"
                          :label="$t('Status')" label-for="isCompleted">
              <v-select transition="" v-model="model.isCompleted"
                        :reduce="s => s.id" label="name"
                        :options="statusList"
                        :clearable="false">
              <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
            </b-form-group>
          </b-col>
        </b-row>

        <modal-footer-button :set-is-submitting="isSubmitting"
                             @submit="submitNewCustomerNote()"></modal-footer-button>

      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
  import customerNoteService from '../../../services/customerNote';
  import staffService from '../../../services/staff';
  import { refreshSmartDuoData, refreshNotification } from '../../../helpers/dataRefresher'

  import DatePicker from '../../../components/shared/DatePicker';
  import TimePicker from '../../../components/shared/TimePicker';
  import ModalFooterButton from '../../../components/shared/ModalFooterButton'

  export default {
    name: 'CustomerNote',
    components: {
      ModalFooterButton,
      DatePicker,
      TimePicker
    },
    props: {
      customerId: String
    },
    data() {
      return {
        model: {
          customerNoteTypeID: '',
          notes: '',
          staffID: '',
          remindDate: '',
          time: '',
          isCompleted: 'false',
          customerID: ''
        },

        noteTypeList: [],
        staffList: [],
        statusList: [],
        isSubmitting: false
      }
    },
    methods: {
      getStaffList() {
        staffService.getStaffList().then(
          (result) => {
            this.staffList = result;
          })
      },
      getStatusList() {
        customerNoteService.getStatusList().then(
          (result) => {
            this.statusList = result;
          })
      },
      getNoteTypeList() {
        customerNoteService.getNoteTypeList().then(
          (result) => {
            this.noteTypeList = result;
          })
      },
      async submitNewCustomerNote() {
        const isValid = await this.$refs.customerNoteObserver.validate();
        if (!isValid) {
          this.$toastr.error(this.$t("ValidationMessage.EmptyAndValidateError"));
          return;
        }

        this.isSubmitting = true;

        customerNoteService.submitNewCustomerNote(this.model).then((result) => {
          this.isSubmitting = false
          if (result) {
            this.$emit('closeModal');
            this.$toastr.success(this.$t("Success"));
            refreshSmartDuoData();
            refreshNotification();
          } else {
            this.$toastr.error(this.$t("Error"));
          }
        }).finally(() => {
          this.isSubmitting = false;
        });
      },
    },
    mounted() {
      this.model.customerID = this.customerId;
      this.model.staffID = this.$store.getters['Auth/currentStaff'].id;

      this.getStaffList();
      this.getStatusList();
      this.getNoteTypeList();
    }
  }
</script>
